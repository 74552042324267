import { graphql, useStaticQuery } from "gatsby"

import Layout from "../../components/Layout"
import ModelList from "../../components/ModelList"
import React from "react"
import Seo from "../../components/seo"

const ElectricPage = () => {
  const data = useStaticQuery(graphql`
  {
    solidBody: allMdx(
      sort: {order: ASC, fields: frontmatter___model}
      filter: {frontmatter: {collections: {eq: "solid body", in: "electric", nin: "basses"}}}
    ) {
      nodes {
        ...modelData
      }
    }
    # semiHollow: allMdx(
    #   sort: {order: ASC, fields: frontmatter___model}
    #   filter: {frontmatter: {collections: {eq: "semi-hollow", in: "electric", nin: "basses"}}}
    # ) {
    #   nodes {
    #     ...modelData
    #   }
    # }
    # acousticElectric: allMdx(
    #   sort: {order: ASC, fields: frontmatter___model}
    #   filter: {frontmatter: {collections: {eq: "acoustic-electric", in: "guitars", nin: "basses"}}}
    # ) {
    #   nodes {
    #     ...modelData
    #   }
    # }
  }
  
  fragment modelData on Mdx {
    frontmatter {
      model
      slug
      swatch {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: BLURRED
            quality: 75
            width: 1200
            aspectRatio: 1.78
          )
        }
      }
    }
  }  
  `);

  const solidBody = data?.solidBody?.nodes.map((x) => x.frontmatter);
  const semiHollow = data?.semiHollow?.nodes.map((x) => x.frontmatter);
  const acousticElectric = data?.acousticElectric?.nodes.map((x) => x.frontmatter);



  return (
    <Layout >
      <Seo title="Talman electric guitars" />
      
      <div className="max-w-1920 mx-auto p-5 lg:p-12">
        <h1 className="page-heading">Talman<br /> electric guitars</h1>

        <section id="solid-body" className="flex flex-col items-center">
          <h2 className="model-cat-heading">
            Solid body <a className="hash-link" href="#solid-body">#</a>
          </h2>
          <ModelList list={solidBody} />

          <h3 className="text-xl">more coming soon!</h3>
        </section>

        <section id="semi-hollow" className="flex flex-col items-center">
          <h2 className="model-cat-heading">Semi-hollow <a className="hash-link" href="#semi-hollow">#</a></h2>
          <ModelList list={semiHollow} />
        </section>

        <section id="acoustic-electric" className="flex flex-col items-center">
          <h2 className="model-cat-heading">Acoustic-electric <a className="hash-link" href="#acoustic-electric">#</a></h2>
          <ModelList list={acousticElectric} />
        </section>
      </div>
    </Layout>
  )
}



export default ElectricPage